<!-- @Author: Yu_Bo -->
<template>
	<div class='select_verify'>
    <div class="user_main">
      <el-form :model="verForm" :rules="verRules" ref="verForm" label-width="0px" class="demo-ruleForm">
        <el-form-item v-if="false">
          <div class="form_text">
            <div class="left">身份验证</div>
            <div class="right">
              <div class="ma_txt">通过支付宝扫描二维码并验证</div>
              <div class="ma_img">
                <img src="@/assets/images/img/service_img.png" alt="">
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="nameId">
          <div class="form_text">
            <div class="left"><span>*</span> 视频平台及账号ID</div>
            <div class="right">
              <el-input v-model="verForm.nameId" placeholder="请输入视频平台及账号ID，可输入多个平台账号。如抖音-xxxxx；快手-xxxxx"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="pcUrl">
          <div class="form_text">
            <div class="left"><span>*</span> PC版个人主页链接</div>
            <div class="right">
              <el-input v-model="verForm.pcUrl" placeholder="请输入该账号PC版个人主页链接"></el-input>
            </div>
          </div>
          <div class="form_tip" @click="linkBtn">如何获取PC版个人主页链接？</div>
        </el-form-item>
        <el-form-item prop="imageUrl">
          <div class="form_text">
            <div class="left"><span>*</span> 上传视频平台粉丝数量截图</div>
            <div class="right">
              <el-upload class="avatar-uploader" action="*"
                :show-file-list="false" :http-request="uploadimg">
                <img v-if="verForm.imageUrl" :src="verForm.imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="referee">
          <div class="form_text">
            <div class="left"><span>*</span> 推荐人手机号</div>
            <div class="right">
              <el-input :disabled="disabled" v-model="verForm.referee" placeholder="请输入推荐人手机号"></el-input>
            </div>
          </div>
          <div class="form_tip_btn">
            若无推荐人请
            <span v-if="disabled" @click="cancelBtn">取消</span>
            <span v-else @click="refereeBtn">点击此处</span>
          </div>
        </el-form-item>
      </el-form>
      <div class="form_btn">
        <span class="btn_left" @click="submitPrev">上一步</span>
        <span class="btn_right" @click="submitForm('verForm')">下一步</span>
      </div>
    </div>
    <!-- 如何获取PC版个人主页链接 -->
    <home-link ref="homeLink"></home-link>
	</div>
</template>

<script>
	import {
	  mixinSettle
	} from "@/mixin/settle"
  import HomeLink from './home_link.vue'//主页链接
	export default {
	  mixins: [mixinSettle],
		components: {HomeLink},
		props: {},
		data() {
			return{
	      verForm:{
	        nameId:'',
	        pcUrl:'',
          imageUrl:'',
          referee:'',
	      },
        disabled:false,
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 如何获取PC版个人主页链接
      linkBtn(){
        this.$refs.homeLink.openDialogBtn()
      },
      // 上传
      uploadimg(item){
        var that = this
        const is_img = ['image/jpeg', 'image/png', 'image/jpg']
        const isJPG = is_img.includes(item.file.type)
        // 限制图片大小
        const isLt2M = item.file.size / 1024 / 1024 <= 10;
        if (!isJPG) {
            that.$errMsg('上传图片只能是 JPG ,JPEG, PNG 格式!');
        }
        if (!isLt2M) {
            that.$errMsg('上传图片大小不能超过 10MB!');
        }
        if (isJPG && isLt2M) {
          let newFile = new FormData()
          newFile.append('type', 1); // type 图片大小限制 1：10M以内；2：15M；3：30M；4：3M
          newFile.append('file', item.file)
          that.$publicApi.uploadImg(newFile).then(res => {
            if (res.code == 1000) {
              that.verForm.imageUrl=res.result.url
            }else{
              this.$errMsg(res.message)
            }
          })
        }
      },
      // 点击此处
      refereeBtn(){
        this.disabled=true
        this.verForm.referee='无'
      },
      // 取消
      cancelBtn(){
        this.disabled=false
        this.verForm.referee=''
      },
	    // 下一步
	    submitForm(formName) {
	      this.$refs[formName].validate((valid) => {
	        if (valid) {
	          // 验证通过
	          this.submitBtn()
	        } else {
	          // 验证不通过
	          return false;
	        }
	      });
	    },
      submitBtn(){
        var that = this
        if(!that.$testVerify.isUrl(that.verForm.pcUrl)){
          that.$errMsg('请输入正确PC版个人主页链接')
          return
        }if(!this.disabled && !that.$testVerify.isvalidPhone(that.verForm.referee)){
          that.$errMsg('请输入正确推荐人手机号')
          return
        }else{
          that.$emit('verifyStep',this.verForm)
        }
      },
      // 上一步
      submitPrev(){
        this.$emit('verifyPrev')
      },
	  },
	}
</script>

<style lang='scss' scoped>
	.select_verify{
    padding-top: 70px;
    padding-left: 200px;
    .user_main{
      width: 760px;
      margin: 0 auto;
    }
    .form_text{
      position: relative;
      display: flex;
      justify-content: center;
      .left{
        width: 200px;
        line-height: 40px;
        padding-right: 20px;
        text-align: right;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
        span{
          color: #E00C25;
        }
      }
      .right{
        width: 560px;
        .el-input{
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333;
        }
        .ma_txt{
          width: 100%;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
        .ma_img{
          width: 100%;
          padding-left: 20px;
          img{
            display: block;
            width: 150px;
            height: 150px;
          }
        }
      }
    }
    .form_tip{
      cursor: pointer;
      margin-left: 200px;
      line-height: 30px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FC0908;
    }
    .form_tip_btn{
      margin-left: 200px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      span{
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FC0908;
      }
    }
    .form_btn{
      padding-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      span{
        cursor: pointer;
        display: block;
        margin: 0 10px;
        width: 118px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        border-radius: 2px;
      }
      .btn_left{
        color: #2E4BF2;
        border: 1px solid #2E4BF2;
      }
      .btn_right{
        color: #FFFFFF;
        background: #2E4BF2;
      }
    }
    ::v-deep .el-form-item{
      margin-bottom: 30px;
      .el-form-item__error{
        left: 200px;
      }
      .avatar-uploader{
        height: 185px;
      }
      .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .avatar-uploader .el-upload:hover {
        border-color: #2E4BF2;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
      }
      .avatar {
        width: 178px;
        height: 178px;
        display: block;
      }
    }
	}
</style>
