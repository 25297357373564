<!-- @Author: Yu_Bo -->
<template>
	<div class='home_link zc_dialog_box'>
		<el-dialog :visible.sync="dialogVisible" width="383px" :close-on-click-modal='false'>
		  <div class="title" slot="title">
		    <div class="name">如何获取PC版个人主页链接</div>
		  </div>
      <div class="link_main">
        <div class="title">第一步：在PC端打开抖音官网douyin.com</div>
        <img class="image" src="../../../../assets/images/img/link_img_a.png" alt="">
        <div class="title">第二步：搜索达人昵称或抖音号点搜索显示下图页面；点头像或达人昵称进到达人主页 </div>
        <img class="image" src="../../../../assets/images/img/link_img_b.png" alt="">
        <div class="title">第三步：双击复制该达人主页链接即可</div>
        <img class="image" src="../../../../assets/images/img/link_img_c.png" alt="">
      </div>
    </el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{
				dialogVisible:false
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开弹框
      openDialogBtn(){
        this.dialogVisible=true
      },
    },
	}
</script>

<style lang='scss' scoped>
	.home_link{
    .link_main{
      width: 100%;
      .title{
        width: 100%;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #E00C25;
      }
      .image{
        display: block;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px;
      }
      .image:last-child{
        margin-bottom: 0;
      }
    }
	}
</style>
