<!-- @Author: Yu_Bo -->
<template>
	<div class='select_enterprise'>
		<div class="user_main">
		  <el-form :model="busForm" :rules="busRules" ref="busForm" label-width="0px" class="demo-ruleForm">
		    <el-form-item prop="name">
		      <div class="form_text">
		        <div class="left"><span>*</span> 企业名称</div>
		        <div class="right">
		          <el-input v-model="busForm.name" maxlength="40" placeholder="请输入企业名称"></el-input>
		        </div>
		      </div>
		    </el-form-item>
		    <el-form-item prop="code">
		      <div class="form_text">
		        <div class="left"><span>*</span> 统一社会信用代码</div>
		        <div class="right">
		          <el-input v-model="busForm.code"  maxlength="18" placeholder="请输入统一社会信用代码"></el-input>
		        </div>
		      </div>
		    </el-form-item>
        <el-form-item prop="lawName">
          <div class="form_text">
            <div class="left"><span>*</span> 法人代表姓名</div>
            <div class="right">
              <el-input v-model="busForm.lawName" maxlength="10" placeholder="请输入法人代表姓名"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="idCard">
          <div class="form_text">
            <div class="left"><span>*</span> 法人代表身份证</div>
            <div class="right">
              <el-input v-model="busForm.idCard" placeholder="请输入法人代表身份证号"></el-input>
            </div>
          </div>
        </el-form-item>
		  </el-form>
		  <div class="form_btn">
        <span class="btn_left" @click="submitPrev">上一步</span>
        <span class="btn_right" @click="submitForm('busForm')">下一步</span>
		  </div>
		</div>
	</div>
</template>

<script>
	import {
	  mixinSettle
	} from "@/mixin/settle"
	export default {
	  mixins: [mixinSettle],
		components: {},
		props: {},
		data() {
			return{
	      busForm:{
	        name:'',
          code:'',
          lawName:'',
	        idCard:'',
	      }
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 上一步
      submitPrev(){
        this.$emit('entertPrev')
      },
	    // 下一步
	    submitForm(formName) {
	      this.$refs[formName].validate((valid) => {
	        if (valid) {
	          // 验证通过
	          this.submitBtn()
	        } else {
	          // 验证不通过
	          return false;
	        }
	      });
	    },
      submitBtn(){
        var that = this
        if(!that.$testVerify.isCard(that.busForm.idCard)){
          that.$errMsg('请输入正确身份证号')
          return
        }else{
          that.$emit('entertStep',that.busForm)
        }
      },
	  },
	}
</script>

<style lang='scss' scoped>
  .select_enterprise{
    padding-top: 70px;
    .user_main{
      width: 510px;
      margin: 0 auto;
    }
    .form_text{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .left{
        width: 150px;
        padding-right: 20px;
        text-align: right;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
        span{
          color: #E00C25;
        }
      }
      .right{
        width: 360px;
        .el-input{
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333;
        }
      }
    }
    .form_btn{
      padding-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      span{
        cursor: pointer;
        display: block;
        margin: 0 10px;
        width: 118px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        border-radius: 2px;
      }
      .btn_left{
        color: #2E4BF2;
        border: 1px solid #2E4BF2;
      }
      .btn_right{
        color: #FFFFFF;
        background: #2E4BF2;
      }
    }
    ::v-deep .el-form-item{
      margin-bottom: 30px;
      .el-form-item__error{
        left: 150px;
      }
    }
  }
</style>
