export const mixinSettle = {
  data() {
    return {
      // 个人
      userRules:{
        name:[
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        idCard:[
          { required: true, message: '请输入身份证号', trigger: 'blur' },
        ]
      },
      // 企业
      busRules:{
        name:[
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        code:[
          { required: true, message: '请输入统一社会信用代码', trigger: 'blur' },
          { pattern: /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/, message: '统一社会信用代码格式不对', trigger: 'blur' },
        ],
        lawName:[
          { required: true, message: '请输入法人代表姓名', trigger: 'blur' },
        ],
        idCard:[
          { required: true, message: '请输入身份证号', trigger: 'blur' },
        ]
      },
      //
      verRules:{
        nameId:[
          { required: true, message: '请输入视频平台及账号ID', trigger: 'blur' },
        ],
        pcUrl:[
          { required: true, message: '请输入该账号PC版个人主页链接', trigger: 'blur' },
        ],
        imageUrl:[
          { required: true, message: '请上传视频平台粉丝数量截图', trigger: 'blur' },
        ],
        referee:[
          { required: true, message: '请输入推荐人手机号', trigger: 'blur' },
        ],
      },
      // 
      phoneRules:{
        code:[
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      }
    }
  },
  computed: {

  },
  created() {},
  mounted() {},
  methods: {},
}
