<!-- @Author: Yu_Bo -->
<template>
	<div class='settle_in'>
    <div class="header">
      <div class="logo">
        <img src="@/assets/images/img/logo_left.png" alt="">
      </div>
    </div>
    <div style="height: 60px;"></div>
    <div class="title">申请入驻</div>
    <div class="settle_box">
      <div class="box_top">
        <div class="top_text">
          <img src="@/assets/images/background/title_background_a.png" alt="">
          <div class="text" :class="topIndex>=1?'text_active':''">
            <span class="t_num">1</span>
            <span class="t_name">选择身份</span>
          </div>
        </div>
        <div class="top_text">
          <img v-if="topIndex>=2" src="@/assets/images/background/title_background_d.png" alt="">
          <img v-else src="@/assets/images/background/title_background_b.png" alt="">
          <div class="text" :class="topIndex>=2?'text_active':''">
            <span class="t_num">2</span>
            <span class="t_name">提交资料</span>
          </div>
        </div>
        <div class="top_text">
          <img src="@/assets/images/background/title_background_c.png" alt="">
          <div class="text" :class="topIndex>=3?'text_active':''">
            <span class="t_num">3</span>
            <span class="t_name">签约</span>
          </div>
        </div>
      </div>
      <!-- 选择身份 第一步中 -->
      <select-identity v-show="topIndex==1" @firstStep='firstStepBtn'></select-identity>
      <!-- 个人 第二步中的1 -->
      <select-user v-show="topIndex==2 && identity==1 && twoIndex==1" @userPrev='userPrevBtn' @userStep='userStepBtn'></select-user>
      <!-- 企业 第二步中的1 -->
      <select-enterprise v-show="topIndex==2 && identity==2 && twoIndex==1" @entertPrev='entertPrevBtn' @entertStep='entertStepBtn'></select-enterprise>
      <!-- 第二步中的2 -->
      <select-verify v-show="topIndex==2 && twoIndex==2" @verifyPrev='verifyPrevBtn' @verifyStep='verifyStepBtn'></select-verify>
      <!-- 第三步 -->
      <select-under-review v-show="topIndex==2 && twoIndex==3"></select-under-review>
    </div>
    <!-- 验证手机号 -->
    <verify-phone ref="verifyTel" @affirm='affirmBtn'></verify-phone>
	</div>
</template>

<script>
  import SelectIdentity from './components/select_identity.vue'//选择身份
  import SelectUser from './components/select_user.vue'//个人
  import SelectEnterprise from './components/select_enterprise.vue'//企业
  import SelectVerify from './components/select_verify.vue'//身份验证
  import SelectUnderReview from './components/select_under_review.vue'//审核种
  import VerifyPhone from './components/verify_phone.vue'//验证手机号
	export default {
		components: {SelectIdentity,SelectUser,SelectEnterprise,SelectVerify,SelectUnderReview,VerifyPhone},
		props: {},
		data() {
			return{
        topIndex:1,
        identity:1,//1个人 2企业
        twoIndex:1,
        // 个人=信息
        user_info:'',
        enterprise_info:'',
        //
        video_info:''
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 第一步-选择身份
      firstStepBtn(val){
        this.topIndex=2
        this.identity=val
        this.twoIndex=1
      },
      // 第二步1-个人
      // 上一步
      userPrevBtn(){
        this.topIndex=1
        this.twoIndex=1
      },
      // 下一步
      userStepBtn(info){
        this.user_info=info
        this.twoIndex=2
      },
      // 第二步1-企业
      // 上一步
      entertPrevBtn(){
        this.topIndex=1
        this.twoIndex=1
      },
      // 下一步
      entertStepBtn(info){
        this.enterprise_info=info
        this.twoIndex=2
      },
      // 第二步2
      // 上一步
      verifyPrevBtn(){
        this.twoIndex=1
      },
      // 下一步
      verifyStepBtn(info){
        this.video_info=info
        this.$refs.verifyTel.openDialogBtn()
      },
      // 提交
      affirmBtn(val){
        var that = this
        var obj = ''
        if(that.identity==1){
          obj = {
            name:that.user_info.name,
            idc:that.user_info.idCard,
          }
        }
        if(that.identity==2){
          obj = {
            name:that.enterprise_info.lawName,
            idc:that.enterprise_info.idCard,
            corp_name:that.enterprise_info.name,
            credit_code:that.enterprise_info.code,
          }
        }
        var params = {
          ...obj,
          type:that.identity,
          video_account:that.video_info.nameId,
          home_url:that.video_info.pcUrl,
          fan_screen:that.video_info.imageUrl,
          referrer_mobile:that.video_info.referee,
          sms_code:val,
        }
        that.$personApi.postKolApply(params).then(res => {
          if (res.code == 1000) {
            that.$refs.verifyTel.cancelBtn()
            that.$succMsg(res.message)
            that.twoIndex=3
          } else {
            that.$refs.verifyTel.closeBtn()
            that.$errMsg(res.message)
          }
        })
      },
    },
	}
</script>

<style lang='scss' scoped>
	.settle_in{
    width: 100%;
    min-width: 1440px;
    min-height: 100%;
    background: #F7F7F7;
    .header{
      position: fixed;
      top: 0;
      z-index: 10;
      width: 100%;
      height: 60px;
      background: #fff;
      .logo{
        width: 1440px;
        height: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        img{
          display: block;
          width: 56px;
          height: 26px;
        }
      }
    }
    .title{
      width: 100%;
      padding: 70px 0 30px;
      text-align: center;
      font-size: 28px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .settle_box{
      width: 1200px;
      padding: 50px 0;
      margin: auto;
      background: #fff;
      border-radius: 4px;
      .box_top{
        width: 100%;
        display: flex;
        justify-content: center;
        .top_text{
          position: relative;
          width: 318px;
          height: 69px;
          img{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: block;
            width: 100%;
            height: 100%;
          }
          .text{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: block;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .t_num{
              display: block;
              width: 30px;
              height: 30px;
              line-height: 29px;
              text-align: center;
              border-radius: 15px;
              border: 1px solid #2E4BF2;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #2E4BF2;
            }
            .t_name{
              padding-left: 12px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2E4BF2;
            }
          }
          .text_active{
            .t_num{
              color: #FFFFFF;
              background: #6F85FF;
              border: 1px solid #FFFFFF;
            }
            .t_name{
              color: #fff;
            }
          }
        }
      }
    }
	}
</style>
